:root {
	--background: #000000;
	--text: #ffffff;
	--font: 'Segoe UI', sans-serif;
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes zoomin {
	from {
		transform: scale(.7);
	}
	to {
		transform: scale(1);
	}
}

body {
	margin: 0;
	background-color: var(--background);
	overflow: hidden;
}

.animation--fadein {
	animation: fadein .5s ease-in-out;
}

.animation--zoomin {
	animation: zoomin .75s ease-in-out;
}

.layout-black {
	background-color: var(--background);
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
}