.loading__background {
	height: 100vh;
	width: 100%;
	background-color: var(--background);
	background-repeat: no-repeat;
	background-position: center;
	position: relative;
	z-index: 1;
}

.loading__percent {
	color: var(--text);
	font-family: var(--font);
	font-size: .8rem;
	font-weight: 400;
	margin: .5rem 0 0 1rem;
}