.configuration--hidden { display: none }
.configuration--visible {
	height: 100vh;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	align-content: center;
}

.configuration__image {
}